import {
  Card,
  CardBody,
  Box,
  Heading,
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  useDisclosure,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  Input,
  InputGroup,
  InputLeftElement,
} from '@chakra-ui/react';
import {SearchIcon} from '@chakra-ui/icons';
import {ColumnDef, SortingState, createColumnHelper} from '@tanstack/react-table';
import React, {
  ReactElement,
  useEffect,
  useRef,
  useState,
  useCallback,
  useMemo,
} from 'react';
import 'react-image-crop/dist/ReactCrop.css';
import useApi from '../../api/hooks/useApi.ts';
import {Video} from '../../types/Video.ts';
import MuxUploader from '@mux/mux-uploader-react';
import {v4 as uuidv4} from 'uuid';
import {useGlobalStore} from '../../store/globalStore.ts';
import {EnvConfig} from '../../base/EnvConfig.ts';
import {PaginationMeta} from '../../types/PaginationMeta.ts';
import Paginator from '../../components/Paginator/Paginator.tsx';
import ResultsTable from '../../components/ResultsTable/ResultsTable.tsx';
import useWebSocket from 'react-use-websocket';

import ResultsTableTitleColumn from '../../components/ResultsTable/ResultsTableTitleColumn.tsx';
import ResultsTableStatusColumn from '../../components/ResultsTable/ResultsTableStatusColumn.tsx';
import ResultsTableStageColumn from '../../components/ResultsTable/ResultsTableStageColumn.tsx';
import ResultsTableDateColumn from '../../components/ResultsTable/ResultsTableDateColumn.tsx';
import ResultsTableEditMenuColumn from '../../components/ResultsTable/ResultsTableEditMenuColumn.tsx';
import {useDebounce, useUpdateEffect} from 'react-use';
import {useQuery, useMutation} from '@tanstack/react-query';
import {AxiosInstance} from '../../api/_AxiosInstance.ts';

const columnHelper = createColumnHelper<Video>();

const getColumns = ({onDelete}: {onDelete: (video: Video) => void}) => [
  columnHelper.display({
    header: 'Video',
    cell: info => (
      <ResultsTableTitleColumn
        item={info.row.original}
        linkBase="/videos/edit/"
        isReady={info.row.original.muxAsset.isReady}
        thumbnailUrl={
          info.row.original.thumbnails?.thumbnail ??
          info.row.original.thumbnailUrl ??
          undefined
        }
      />
    ),
  }),
  columnHelper.display({
    header: 'Published',
    cell: info => (
      <ResultsTableStatusColumn
        status={info.row.original.published}
        onText="Published"
        offText="Unpublished"
      />
    ),
  }),
  columnHelper.display({
    header: 'Status',
    cell: info => (
      <ResultsTableStatusColumn
        status={info.row.original.muxAsset.isReady}
        onText="Ready"
        offText="Uploading"
      />
    ),
  }),
  columnHelper.display({
    header: 'CC Generated',
    cell: info => (
      <ResultsTableStatusColumn
        status={!!info.row.original.muxAsset.tracks?.length}
        onText="yes"
        offText="no"
      />
    ),
  }),
  columnHelper.accessor('stage', {
    header: 'Stage',
    cell: info => {
      const stageValue = info.getValue();
      return <ResultsTableStageColumn stage={stageValue} />;
    },
    meta: {
      isNumeric: true,
    },
  }),
  columnHelper.accessor('createdAt', {
    header: 'Created Date',
    cell: info => {
      const dateValue = info.getValue();
      return <ResultsTableDateColumn date={dateValue} />;
    },
    meta: {
      isNumeric: true,
    },
  }),
  columnHelper.display({
    id: 'edit',
    cell: info => (
      <ResultsTableEditMenuColumn item={info.row.original} onDelete={onDelete} />
    ),
  }),
];

const VideosPage = (): ReactElement => {
  const [uploadUrl, setUploadUrl] = useState(null);
  const [seed, setSeed] = useState(uuidv4());

  const [searchQuery, setSearchQuery] = useState('');
  const [sorting, setSorting] = React.useState<SortingState>([
    {
      id: 'createdAt',
      desc: true,
    },
  ]);

  const [filters, setFilters] = React.useState<{
    sorting: SortingState;
    currentPage: number;
    searchQuery: string;
  }>({
    sorting: [],
    currentPage: 1,
    searchQuery: '',
  });

  function updateFilters(newFilters: Partial<typeof filters>) {
    setFilters({
      ...filters,
      ...newFilters,
    });
  }

  useDebounce(
    () => {
      updateFilters({searchQuery, currentPage: 1});
    },
    500,
    [searchQuery],
  );

  useUpdateEffect(() => {
    updateFilters({sorting, currentPage: 1});
  }, [sorting]);

  const [videoToDelete, setVideoToDelete] = React.useState<{
    videoId: number | null;
    muxAssetId: string | null;
  }>({
    videoId: null,
    muxAssetId: null,
  });

  const {isOpen, onOpen, onClose} = useDisclosure();

  const {
    isOpen: deleteDialogIsOpen,
    onOpen: deleteDialogOnOpen,
    onClose: deleteDialogOnClose,
  } = useDisclosure();

  const cancelRef = useRef(null);

  const supabaseAuthSession = useGlobalStore.getState().supabaseAuthSession;

  // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-assignment
  const {lastMessage} = useWebSocket(EnvConfig.BACKEND_WS_URL, {
    protocols: supabaseAuthSession?.access_token ?? '',
  });

  useEffect(() => {
    // when asset is created or ready on server re-fetch the data
    if (
      lastMessage !== null &&
      (lastMessage.data === 'ASSET_CREATED' || lastMessage.data === 'ASSET_READY')
    ) {
      getVideosQuery.refetch();
    }
  }, [lastMessage]);

  function handleOpen() {
    return () => {
      setSeed(uuidv4());
      fetchMuxUploadUrl();
      onOpen();
    };
  }

  async function fetchMuxUploadUrl() {
    const supabaseAuthSession = useGlobalStore.getState().supabaseAuthSession;

    if (supabaseAuthSession) {
      const response = await fetch(EnvConfig.BACKEND_BASE_URL + '/mux/upload-url', {
        method: 'POST',
        mode: 'cors',
        headers: {
          Authorization: 'Bearer ' + supabaseAuthSession.access_token,
          // "Content-Type": "application/x-www-form-urlencoded",
        },
        // Example of passing the videoId as a parameter - will be used for the "replace video" flow
        // body: new URLSearchParams({
        //   'videoId': '24',
        // }).toString()
      });

      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const responseJson = await response.json();
      console.log('-----server response-----');
      console.log(responseJson);
      console.log('-----end server response-----');
      console.log(responseJson.url);

      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      setUploadUrl(responseJson.url);
    }
  }
  const [data, setData] = React.useState<{videos: Video[]; meta: PaginationMeta | null}>({
    videos: [],
    meta: null,
  });

  const limit = 10;
  const getVideosQuery = useQuery({
    queryKey: ['videos', filters],
    queryFn: async () => {
      const params: Record<string, string | number> = {
        page: filters.currentPage,
        pageSize: limit,
      };
      if (filters.sorting?.[0]) {
        params.sortField = filters.sorting[0].id;
        params.sortOrder = filters.sorting[0].desc ? 'DESC' : 'ASC';
      }
      if (filters.searchQuery) {
        params.title = filters.searchQuery;
      }
      const {data} = await AxiosInstance.get<{videos: Video[]; meta: PaginationMeta}>(
        '/admin/videos',
        {
          params,
        },
      );
      return data;
    },
  });

  const {data: apiData} = getVideosQuery;

  const {trigger: deleteMuxAsset, isLoading: isDeleteing} = useApi<string>({
    url: `/mux/delete-asset`,
    method: 'POST',
    fetchOnMount: false,
  });

  const {trigger: deleteVideoAsset, isLoading: isDeletingVideo} = useApi<string>({
    url: `/admin/videos/${videoToDelete.videoId}`,
    method: 'DELETE',
    fetchOnMount: false,
  });

  const handleDeleteButtonPressed = useCallback((video: Video) => {
    const videoId = video.id;
    const muxAssetId = video.muxAsset.assetId;
    setVideoToDelete({videoId, muxAssetId});
    deleteDialogOnOpen();
  }, []);

  const columns = useMemo(
    () => getColumns({onDelete: handleDeleteButtonPressed}),
    [handleDeleteButtonPressed],
  );

  useEffect(() => {
    if (apiData) {
      setData(apiData);
    }
  }, [apiData]);

  const handleCancelDelete = () => {
    setVideoToDelete({
      videoId: null,
      muxAssetId: null,
    });
    deleteDialogOnClose();
  };

  const deleteVideoMutation = useMutation({
    mutationFn: handleDeleteVideo,
  });

  async function handleDeleteVideo() {
    await deleteVideoAsset();
    await deleteMuxAsset({muxAssetId: videoToDelete.muxAssetId});
    setVideoToDelete({
      videoId: null,
      muxAssetId: null,
    });
    await getVideosQuery.refetch();
    deleteDialogOnClose();
  }

  return (
    <Box height="auto">
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Upload Video</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <MuxUploader key={seed} endpoint={uploadUrl} hidden={!uploadUrl} />
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <AlertDialog
        isOpen={deleteDialogIsOpen}
        leastDestructiveRef={cancelRef}
        onClose={handleCancelDelete}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete Video
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure? You can not undo this action afterwards.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button
                ref={cancelRef}
                onClick={handleCancelDelete}
                isDisabled={deleteVideoMutation.isPending}>
                Cancel
              </Button>
              <Button
                colorScheme="red"
                onClick={() => deleteVideoMutation.mutate()}
                ml={3}
                isLoading={deleteVideoMutation.isPending}>
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
      <Box maxWidth={'container.xl'}>
        <Flex justifyContent="space-between">
          <Heading mb={4}>Videos</Heading>
          <Button onClick={handleOpen()} mb={10} colorScheme="black">
            Upload Video
          </Button>
        </Flex>
        <Card maxWidth="container.xl">
          <Box mb={0} px={10} pt={10}>
            <InputGroup>
              <InputLeftElement pointerEvents="none">
                <SearchIcon color="gray.400" />
              </InputLeftElement>
              <Input
                placeholder="Search"
                value={searchQuery}
                onChange={e => setSearchQuery(e.target.value)}
                mb={4}
                borderRadius="5"
                borderColor="gray.300"
                _placeholder={{color: 'gray.400'}}
                _focus={{borderColor: 'blue.500'}}
                pl="10"
                maxWidth="350px"
              />
            </InputGroup>
          </Box>
          <CardBody>
            <ResultsTable
              columns={columns as ColumnDef<any>[]}
              data={data.videos}
              onSortChange={setSorting}
              sorting={sorting}
              manualSorting
            />
            {apiData?.meta && (
              <Paginator
                onPageChange={({selected}) => {
                  updateFilters({currentPage: selected + 1});
                }}
                pageCount={apiData.meta.pageCount}
                forcePage={filters.currentPage - 1}
              />
            )}
          </CardBody>
        </Card>
      </Box>
    </Box>
  );
};

export default VideosPage;
