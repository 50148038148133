import {Avatar, Button, HStack} from '@chakra-ui/react';
import queryClient from '../../api/queryClient';
import {User} from '../../types/User';
import TextareaAutosize from 'react-textarea-autosize';

export interface NotificationSendCommentInputProps {
  comment: string;
  setComment: (value: string) => void;
  loadingCreatingReply: boolean;
  handlePostClick: () => void;
}

export default function NotificationSendCommentInput(
  props: NotificationSendCommentInputProps,
) {
  const {comment, setComment, loadingCreatingReply, handlePostClick} = props;

  const user: User | undefined = queryClient.getQueryData(['user-me']);

  return (
    <HStack width="full">
      <Avatar size="sm" src={user?.profilePictureUrl ?? ''} />
      <TextareaAutosize
        minRows={1}
        placeholder="Add a comment..."
        value={comment}
        onChange={e => setComment(e.target.value ?? '')}
        disabled={loadingCreatingReply}
        style={{
          borderRadius: '8px',
          borderColor: 'gray',
          borderWidth: 1,
          width: '100%',
          padding: '0.5rem',
        }}
      />
      <Button
        h="1.75rem"
        size="sm"
        bg="transparent"
        color="blue.500"
        isDisabled={!comment || loadingCreatingReply}
        isLoading={loadingCreatingReply}
        onClick={handlePostClick}>
        Post
      </Button>
    </HStack>
  );
}
