/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import {
  Avatar,
  Box,
  Button,
  DrawerCloseButton,
  Flex,
  HStack,
  IconButton,
  Image,
  Text,
  VStack,
} from '@chakra-ui/react';
import {CommentPreviewType, NotificationType} from '../../types/Notifications';
import {
  headingsPlugin,
  linkDialogPlugin,
  linkPlugin,
  MDXEditor,
  MDXEditorMethods,
} from '@mdxeditor/editor';
import {useRef} from 'react';
import {AmitySdkPost} from '../../types/amity/AmitySdkPost';
import {FaHeart, FaRegComment, FaRegHeart} from 'react-icons/fa';
import CoachBadge from '../Common/Badges/CoachBadge';
import ReactTimeago from 'react-timeago';
import agoFormatter from '../../helpers/agoFormatter';

interface CommentThreadButtonProps {
  isCommentThread: boolean;
  setIsCommentThread: () => void;
  loading: boolean;
  viewingWhat: string;
}

function CommentThreadButton(props: Readonly<CommentThreadButtonProps>) {
  const {isCommentThread, setIsCommentThread, loading, viewingWhat} = props;

  return (
    <Flex justify="space-between" p={4} overflowY="auto" align="center">
      <Text>
        {isCommentThread
          ? `You're viewing a single comment thread.`
          : `You're viewing all the ${viewingWhat}.`}
      </Text>
      <Button onClick={setIsCommentThread} isDisabled={loading}>
        {isCommentThread ? `View all the ${viewingWhat}.` : 'View single comment thread'}
      </Button>
    </Flex>
  );
}

export interface NotificationHeaderProps {
  programName?: string;
  dayName?: string;
  stepName?: string;
  isCommentThread: boolean;
  setIsCommentThread: () => void;
  onPostLikeClick?: () => void;
  loading: boolean;
  commentType: CommentPreviewType | null;
  thumbnailUrl?: string;
  notificationType: NotificationType;
  amityPost: AmitySdkPost | null;
}

function NotificationHeader(props: Readonly<NotificationHeaderProps>) {
  const {
    programName = '',
    dayName = '',
    stepName,
    isCommentThread,
    setIsCommentThread,
    onPostLikeClick,
    loading,
    commentType,
    thumbnailUrl,
    notificationType,
    amityPost,
  } = props;

  const mdxEditorRef = useRef<MDXEditorMethods>(null);

  const viewingWhat =
    commentType === CommentPreviewType.COMMENT || commentType === null
      ? 'comments'
      : 'questions';

  if (
    [NotificationType.COMMUNITY_COMMENT, NotificationType.COMMUNITY_POST].includes(
      notificationType,
    ) &&
    amityPost
  ) {
    return (
      <Box borderRadius="24px" padding="16px 24px 0px 24px">
        <Flex alignItems="center" justifyContent="space-between">
          <Flex alignItems="center">
            <Avatar
              src={amityPost.creator?.avatarCustomUrl}
              name={amityPost.creator?.displayName}
              width="42px"
              height="42px"
            />
            <Box ml="3">
              <HStack>
                <Text fontSize="16px" lineHeight="18px" fontWeight="600" color="#1B1A1A">
                  {amityPost.creator?.displayName}
                </Text>
                {amityPost.tags.includes('admin') && <CoachBadge />}
              </HStack>
              <Text
                fontSize="10px"
                lineHeight="14px"
                fontWeight="600"
                color="#8E959E"
                letterSpacing="9%"
                textAlign="left">
                <ReactTimeago
                  date={amityPost.createdAt}
                  minPeriod={60}
                  formatter={agoFormatter}
                />
              </Text>
            </Box>
          </Flex>
        </Flex>

        <Box mt="3">
          <Box>
            <MDXEditor
              readOnly
              ref={mdxEditorRef}
              markdown={amityPost.data.text}
              plugins={[linkPlugin(), linkDialogPlugin(), headingsPlugin()]}
            />
          </Box>
          <HStack>
            {amityPost.metadata?.postImages?.map((url: string) => (
              <Image
                key={url}
                src={url}
                alt="Post image"
                mt="3"
                borderRadius="lg"
                maxWidth="200px"
                maxHeight={400}
              />
            ))}
          </HStack>
        </Box>

        <HStack mt={2}>
          <HStack>
            <IconButton
              aria-label="Like"
              icon={
                amityPost.myReactions?.includes('like') ? (
                  <FaHeart color="red" />
                ) : (
                  <FaRegHeart color="gray.500" />
                )
              }
              variant="ghost"
              size="sm"
              minWidth={0}
              onClick={onPostLikeClick}
              _hover={{bgColor: 'none'}}
            />
            <Text fontSize="14px" lineHeight="18px" fontWeight="400" color="#1B1A1A">
              {amityPost.reactions?.like > 0
                ? `${amityPost.reactions?.like} Likes`
                : 'Like'}
            </Text>
          </HStack>

          <HStack marginLeft={4}>
            <FaRegComment />
            <Text fontSize="14px" lineHeight="18px" fontWeight="400" color="#1B1A1A">
              {amityPost.commentsCount > 0
                ? amityPost.commentsCount + ' Comments'
                : 'Comment'}
            </Text>
          </HStack>
        </HStack>

        {notificationType === NotificationType.COMMUNITY_COMMENT && (
          <CommentThreadButton
            isCommentThread={isCommentThread}
            setIsCommentThread={setIsCommentThread}
            loading={loading}
            viewingWhat={viewingWhat}
          />
        )}
      </Box>
    );
  }

  if (
    [NotificationType.COMMUNITY_COMMENT, NotificationType.COMMUNITY_POST].includes(
      notificationType,
    )
  ) {
    return null;
  }

  return (
    <Box paddingTop={4} paddingX={8} width="100%" bg="white">
      <HStack paddingBottom={4} borderBottomWidth="1px">
        <Image
          borderRadius="16px"
          w="85px"
          height="67px"
          src={thumbnailUrl}
          alt="Video Thumbnail"
          fallback={
            <Box w="85px" h="67px" bg="gray.200" borderRadius="16px">
              <Text fontSize="sm" color="gray.500" textAlign="center" paddingY={2}>
                No Thumbnail
              </Text>
            </Box>
          }
        />
        <VStack align="start" ml={5} spacing={0} padding={0}>
          <Text fontSize="lg" color="gray.500">
            {programName} • {dayName}
          </Text>
          {stepName && (
            <Text fontSize="2xl" fontWeight="400">
              {stepName}
            </Text>
          )}
        </VStack>
      </HStack>
      <CommentThreadButton
        isCommentThread={isCommentThread}
        setIsCommentThread={setIsCommentThread}
        loading={loading}
        viewingWhat={viewingWhat}
      />
      <DrawerCloseButton />
    </Box>
  );
}

export default NotificationHeader;
