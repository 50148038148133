import React, {useState, useRef} from 'react';
import {
  Box,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
} from '@chakra-ui/react';
import ReactCrop, {centerCrop, Crop, makeAspectCrop, PixelCrop} from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import {useDebounceEffect} from '../../pages/VideosPages/EditVideo/useDebounceEffect.ts';
import {canvasPreview} from '../../pages/VideosPages/EditVideo/canvasPreview';

interface UploadImageModalProps {
  isOpen: boolean;
  onClose: () => void;
  onImageUpload: (
    blobs: {
      blob: Blob;
      name: string;
    }[],
  ) => Promise<void>;

  aspect?: number;
  headerText: string;
  sizes: {width: number; height: number; name: string}[];
  uploadOriginalSize?: boolean;
  circularCrop?: boolean;
}

function centerAspectCrop(mediaWidth: number, mediaHeight: number, aspect: number) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: '%',
        width: 90,
      },
      aspect,
      mediaWidth,
      mediaHeight,
    ),
    mediaWidth,
    mediaHeight,
  );
}
const UploadImageModal: React.FC<UploadImageModalProps> = ({
  isOpen,
  onClose,
  onImageUpload,
  aspect = 1.77,
  headerText,
  sizes: sizesProps = [],
  uploadOriginalSize = false,
  circularCrop = false,
}) => {
  const [imgSrc, setImgSrc] = useState('');
  const [crop, setCrop] = useState<Crop>();
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>();
  const [thumbnailUploading, setThumbnailUploading] = useState(false);

  const previewCanvasRef = useRef<HTMLCanvasElement>(null);
  const imgRef = useRef<HTMLImageElement>(null);

  const onSelectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      if (!e.target.files[0].type.includes('image')) {
        window.toast({
          description: 'Please select an image',
          status: 'error',
          position: 'top',
          isClosable: true,
          duration: 5000,
        });
        return;
      }
      setCrop(undefined); // Makes crop preview update between images.
      const reader = new FileReader();
      reader.addEventListener('load', () => setImgSrc(reader.result?.toString() ?? ''));
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const onImageLoad = (e: React.SyntheticEvent<HTMLImageElement>) => {
    if (aspect) {
      const {width, height} = e.currentTarget;
      setCrop(centerAspectCrop(width, height, aspect));
    }
  };

  const updateThumbnail = async () => {
    try {
      setThumbnailUploading(true);
      const image = imgRef.current;
      const previewCanvas = previewCanvasRef.current;
      if (!image || !previewCanvas || !completedCrop) {
        window.toast({
          description: 'Crop canvas does not exist',
          status: 'error',
          position: 'top',
          isClosable: true,
          duration: 5000,
        });
        return;
      }

      const scaleX = image.naturalWidth / image.width;
      const scaleY = image.naturalHeight / image.height;

      const sizes = [...sizesProps];

      if (uploadOriginalSize) {
        sizes.push({
          width: completedCrop.width * scaleX,
          height: completedCrop.height * scaleY,
          name: 'original',
        });
      }

      const createCanvasBlob = async (width: number, height: number, name: string) => {
        const offscreen = new OffscreenCanvas(width, height);
        const ctx = offscreen.getContext('2d');
        if (!ctx) {
          window.toast({
            description: 'No 2d context',
            status: 'error',
            position: 'top',
            isClosable: true,
            duration: 5000,
          });
          return null;
        }

        ctx.drawImage(
          previewCanvas,
          0,
          0,
          previewCanvas.width,
          previewCanvas.height,
          0,
          0,
          width,
          height,
        );

        const blob = await offscreen.convertToBlob({
          type: 'image/png',
        });
        return {
          blob,
          name,
        };
      };

      const blobs = await Promise.all(
        sizes.map(size => createCanvasBlob(size.width, size.height, size.name)),
      );

      await onImageUpload(blobs as {blob: Blob; name: string}[]);

      setImgSrc('');
      onClose();
      setThumbnailUploading(false);
    } catch (e) {
      setThumbnailUploading(false);
      console.log('error', e);
      window.toast({
        description: 'Something went wrong. Please try again.',
        status: 'error',
        position: 'top',
        isClosable: true,
        duration: 5000,
      });
    }
  };

  useDebounceEffect(
    () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        void canvasPreview(imgRef.current, previewCanvasRef.current, completedCrop, 1, 0);
      }
    },
    100,
    [completedCrop],
  );

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{headerText}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box className="Crop-Controls" mb={4}>
            <input type="file" accept="image/*" onChange={onSelectFile} />
          </Box>
          {!!imgSrc && (
            <ReactCrop
              crop={crop}
              onChange={(_, percentCrop) => setCrop(percentCrop)}
              onComplete={c => setCompletedCrop(c)}
              aspect={aspect}
              minHeight={100}
              circularCrop={circularCrop}>
              <img ref={imgRef} alt="Crop me" src={imgSrc} onLoad={onImageLoad} />
            </ReactCrop>
          )}
          {!!completedCrop && (
            <div style={{display: 'none'}}>
              <canvas
                ref={previewCanvasRef}
                style={{
                  border: '1px solid black',
                  objectFit: 'contain',
                  width: completedCrop.width,
                  height: completedCrop.height,
                }}
              />
            </div>
          )}
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={() => void updateThumbnail()}
            colorScheme="blue"
            isDisabled={!completedCrop}
            isLoading={thumbnailUploading}>
            Crop
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default UploadImageModal;
